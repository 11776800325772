

import $ from 'jquery';
import Blazy from 'blazy';


/*------------------------------------------------------------------
[ App ]
-------------------------------------------------------------------*/


class App {


    constructor(){

        /*
        properties / flags / data store / environment
        */
        this.IS_SAFARI = (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)) ? true : false;
        this.SUPPORTS_BACKGROUNDSIZE = (Modernizr.backgroundsize) ? true : false;
        this.SUPPORTS_SVG = (Modernizr.svg) ? true : false;
        this.SUPPORTS_TOUCH = (Modernizr.touch) ? true : false;
        self.HEADER_HIDDEN = false;
        this.ISANIMATING = false;


        // dom
        this.$html = $('html');
        this.$header = $('[data-header]');

        // start ...
        this.init();

        //console.log('app : start');

    }


    init(){

        let self = this;

        self.initLayerClickHandlers();
        self.initToggleClickHandlers();
        self.lazyLoad();
        self.openExternalLinksInNewTab();

        if(!self.SUPPORTS_TOUCH){
            // self.initHeaderScrollEvents();
        }

        //console.log('app : init');
    }


    initLayerClickHandlers() {

        let self = this;
        let $documnet = $(document);
        let $element = $('[data-toggle-layer]');

        $documnet.on('click', '[data-toggle-layer]', function(event){

            event.stopPropagation();
            event.preventDefault();

            let $clicked = $(this);
            let $clickedLayer = $(this).attr('data-toggle-layer');
            let $target = $('[data-layer="'+$clickedLayer+'"]');

            //console.log($clicked);
            //console.log($clickedLayer);
            //console.log($target);

            if ($target) {

                if ($target.is(':visible')){

                    $target.removeAttr('style');

                } else {

                    $target.css({
                        display:'block'
                    });
                }
            }
        });
    }


    initToggleClickHandlers() {

        let self = this;
        let $documnet = $(document);
        let $element = $('[data-toggle-section]');

        $documnet.on('click', '[data-toggle-section]', function(event){

            event.stopPropagation();
            event.preventDefault();

            let $clicked = $(this);
            let $clickedLayer = $(this).attr('data-toggle-section');
            let $target = $('[data-toggle-section-target="'+$clickedLayer+'"]');

            //console.log($clicked);
            //console.log($clickedLayer);
            //console.log($target);

            if ($target) {

                if ($target.hasClass('is-open')){

                    $clicked.html('<img class="entry__single__toggle-content-icon" src="https://icon.now.sh/plus/bbbdc0" alt="read more" />');
                    $target.removeClass('is-open');

                } else {

                    $clicked.html('<img class="entry__single__toggle-content-icon" src="https://icon.now.sh/minus/bbbdc0" alt="read less" />');
                    $target.addClass('is-open');
                }
            }
        });
    }


    initHeaderScrollEvents() {

        let self = this;
        let scrollPosition = 0;
        let target = self.$header.outerHeight();

        //console.log('app : initHeaderScrollEvents');

        $(window).on('scroll', function (event) {

            let newScrollPosition = $(this).scrollTop();

            if (!self.ISANIMATING) {

                if ( newScrollPosition > scrollPosition ) {

                    if (newScrollPosition > target) self.hideHeader();

                } else {

                    if (self.HEADER_HIDDEN) self.showHeader();
                }

                if (scrollPosition <= target && self.HEADER_HIDDEN) self.showHeader();
            }

            scrollPosition = newScrollPosition;

        });
    }


    showHeader() {

        let self = this;

        if (self.ISANIMATING) return;

        self.ISANIMATING = true;

        //console.log('app : showHeader');

        // animate
        self.$header.removeClass('is-hidden');

        setTimeout(function(){
            self.ISANIMATING = false;
            self.HEADER_HIDDEN = false;
        }, 250);

    }


    hideHeader() {

        let self = this;
        let target = self.$header.outerHeight();

        if (self.ISANIMATING) return;

        self.ISANIMATING = true;

        //console.log('app : hideHeader');

        // animate
        self.$header.addClass('is-hidden');


        setTimeout(function(){
            self.ISANIMATING = false;
            self.HEADER_HIDDEN = true;
        }, 250);

    }

    lazyLoad(){

        let self = this;
        let $scope = $(document);
        let $lazyTargets = $scope.find('[data-lazy]');

        //console.log('Lazy : initLazy');
        //console.log(`Lazy : ${$lazyTargets.length} found`);

        let bLazy = new Blazy({
            container: '.main',
            selector: '[data-lazy]',
            successClass: 'is-loaded',
            success(element) {
                console.log('lazy loaded');
                $(element).removeAttr('data-lazy');
            }
        });
    }


    scrollToTop() {

        //console.log('app.scrollToTop');

        $('html, body').animate({ scrollTop: 0}, 200, 'linear');
    }

    openExternalLinksInNewTab() {
        var $links = $('a[href]:not([href^="mailto\\:"], [href$="\\#"])')
        $links.each(function() {
           var a = new RegExp('/' + window.location.host + '/');
           if(!a.test(this.href)) {
               $(this).click(function(event) {
                   event.preventDefault();
                   event.stopPropagation();
                   window.open(this.href, '_blank');
               });
           }
        });
    }
}

// init
$(function(){

    new App();

});
